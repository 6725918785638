<template>
  <v-footer id="home-footer" color="black" dark min-height="72">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="
              d-flex
              flex-wrap
              justify-md-start justify-center justify-md-none
            "
          >
            <base-btn
              class="pa-3"
              height="auto"
              outlined
              @click="openCommunityLink()"
            >
              Join Our Community
            </base-btn>
            <!-- <template v-for="(s, i) in social">
              <a
                :key="s.name"
                class="white--text pa-1 pa-md-0"
                :href="s.link"
                v-text="s.name"
                target="_blank"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template> -->
          </div>
        </v-col>

        <!-- <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; 2021 InstaParty.gg
        </v-col> -->
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: [
      {
        name: "Discord",
        link: "https://discord.gg/WZ7EgH7w4d",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/PartyUpLink",
      },
    ],
  }),
  methods: {
    openCommunityLink: function (e) {
      window.open("https://discord.gg/WZ7EgH7w4d", "_blank");
    },
  },
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
